import React from "react";
import { Link } from "gatsby";

const ServicesHeader = ({ serviceData }) => {
  return (
    <section
      className="page-header proj-det bg-img parallaxie valign"
      style={{ backgroundImage: `url(${serviceData.projectHeaderImage})` }}
      data-overlay-dark="7"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h1>{serviceData.title}</h1>
            </div>
          </div>
          <div className="col-lg-3">
          <div className="cont">
              <p>
                {serviceData.summary}
              </p>
            </div>
          </div>
        </div>        
      </div>
    </section>
  );
};

export default ServicesHeader;
