import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ServicesHeader from "components/Services-header/services-header";
import ServicesDetail from "components/Services-detail/services-detail";
import CallToAction from "components/Call-to-action/call-to-action";
import DarkTheme from "layouts/Dark";
import {graphql} from "gatsby";

const ServicePage = ({
    data: {
      service
    }
}) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  console.log("Creating service page ");
  console.log(service);
  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <div className="wrapper">
        <ServicesHeader serviceData={service} />
        <ServicesDetail serviceData={service} />
        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = ({
  data: {
    service
  }
}) => {
  return (
    <>
       <title>{service.metaTitle}</title>
       <meta name="title" content={service.metaTitle} />
       <meta name="description" content={service.metaDescription} />
    </>
  )
}

export const query = graphql`
  query($id: String) {
    service(id: {eq: $id}) {
      id
      slug
      title
      metaTitle
      metaDescription
      summary
      description
      projectHeaderImage
      next
      previous
    }
  }
`;

export default ServicePage;
