import { Link } from "gatsby";
import React from "react";

const ServicesDetail = ({ serviceData }) => {
  const SERVICE_ROOT="/service/";
  let next = SERVICE_ROOT + serviceData.next;
  let previous = SERVICE_ROOT + serviceData.previous;
  return (
    <section className="intro-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h3>
                <span>01 </span> What is {serviceData.title}?
              </h3>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text js-scroll__content">
              <p className="extra-text">
                {serviceData.description}
              </p>
            </div>
          </div>
        </div>
        <div className="row"><hr></hr></div>
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h3>
                <span>02 </span> Our Role
              </h3>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text js-scroll__content">
              <p className="extra-text">
                The team assigned to your project will have experience across all aspects of the software development lifecycle. Our unique blend of on and offshore talent can be tuned to your specific needs and delivering to your individual project objectives. <a href="/contact">Contact us</a> now to learn more and get scheduled for a free inital consultation.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Link to={previous} className="butn bord curve">&lt; Previous</Link>
            &nbsp;&nbsp;
            <Link href={next} className="butn bord curve">Next &gt;</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesDetail;
